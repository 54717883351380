import urljoin from "url-join";
import { BaseAPI } from "./BaseApi";
import { apiGet, apiPatch, apiPost, buildUrlWithPaginateQuery } from "./helpers";
class PromoterApi extends BaseAPI {
    getPromoters = async (queryOptions = {}) => {
        const { basePath, fetch: customFetch } = this;
        const endCustomers = await apiGet(buildUrlWithPaginateQuery(queryOptions, basePath, "/promoter"), customFetch);
        return endCustomers;
    };
    getPromoter = async (id, options = {}) => {
        const { basePath, fetch: customFetch } = this;
        return apiGet(urljoin(basePath, "promoter", encodeURIComponent(id)), customFetch, options);
    };
    getPromoterByUserId = async (userId, options = {}) => {
        if (!userId)
            return undefined;
        const { basePath, fetch: customFetch } = this;
        return apiGet(urljoin(basePath, "promoter", "userId", encodeURIComponent(userId)), customFetch, options);
    };
    createPromoter = async (data, options = {}) => {
        return apiPost(urljoin(this.basePath, "promoter"), this.fetch, data, options);
    };
    updatePromoter = async (id, data, options = {}) => {
        return apiPatch(urljoin(this.basePath, "promoter", encodeURIComponent(id)), this.fetch, data, options);
    };
    activatePromoter = async (id) => {
        const { basePath, fetch: customFetch } = this;
        return apiPost(urljoin(basePath, "promoter", encodeURIComponent(id), "activate"), customFetch);
    };
    deactivatePromoter = async (id) => {
        const { basePath, fetch: customFetch } = this;
        return apiPost(urljoin(basePath, "promoter", encodeURIComponent(id), "deactivate"), customFetch);
    };
}
export default PromoterApi;
