import urljoin from "url-join";
import { RCABaseAPI as BaseAPI } from "./BaseApi";
import { apiGet, apiPatch, apiPost } from "./helpers";
const userRawToUser = (u) => ({
    ...u,
    groups: u.groups.split(","),
    is_active: u.is_active !== undefined ? u.is_active === "true" : undefined,
});
export class UsersInfoApi extends BaseAPI {
    async usersRead(id, options) {
        const userRaw = await apiGet(this.fetch, urljoin(this.basePath, `/users/${encodeURIComponent(id)}`), options);
        const user = userRawToUser(userRaw);
        return user;
    }
    usersCreate(params, options = {}) {
        const data = {
            ...params,
            groups: params.groups?.join(","),
        };
        return apiPost(this.fetch, urljoin(this.basePath, `/users`), data, options);
    }
    async usersSearch(username, firstName, lastName, groups, firstNameOrLastName, options = {}) {
        const queryParameters = {};
        if (username !== undefined) {
            queryParameters.username = username;
        }
        if (firstName !== undefined) {
            queryParameters.first_name = firstName;
        }
        if (lastName !== undefined) {
            queryParameters.last_name = lastName;
        }
        if (groups !== undefined) {
            queryParameters.groups = groups;
        }
        if (firstNameOrLastName !== undefined) {
            queryParameters.first_name_or_last_name = firstNameOrLastName;
        }
        const usersRaw = await apiGet(this.fetch, urljoin(this.basePath, `/users/search?${new URLSearchParams(queryParameters).toString()}`), options);
        const users = usersRaw.map(userRawToUser);
        return users;
    }
    async usersList(id, username, firstName, lastName, email, picture, ids, groups, isActive, options = {}) {
        const queryParameters = {};
        if (id !== undefined) {
            queryParameters.id = id;
        }
        if (username !== undefined) {
            queryParameters.username = username;
        }
        if (firstName !== undefined) {
            queryParameters.first_name = firstName;
        }
        if (lastName !== undefined) {
            queryParameters.last_name = lastName;
        }
        if (email !== undefined) {
            queryParameters.email = email;
        }
        if (picture !== undefined) {
            queryParameters.picture = picture;
        }
        if (ids !== undefined) {
            queryParameters.ids = ids;
        }
        if (groups !== undefined) {
            queryParameters.groups = groups;
        }
        if (isActive !== undefined) {
            queryParameters.is_active = isActive;
        }
        const usersRaw = await apiGet(this.fetch, urljoin(this.basePath, `/users?${new URLSearchParams(queryParameters).toString()}`), options);
        const users = usersRaw.map(userRawToUser);
        return users;
    }
    usersPartialUpdate(id, params, options = {}) {
        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error("Required parameter id was null or undefined when calling usersPartialUpdate.");
        }
        // verify required parameter 'data' is not null or undefined
        if (params === null || params === undefined) {
            throw new Error("Required parameter params was null or undefined when calling usersPartialUpdate.");
        }
        const data = {
            ...params,
            groups: params.groups?.join(","),
        };
        return apiPatch(this.fetch, urljoin(this.basePath, `/users/${encodeURIComponent(id)}`), data, options);
    }
    usersUpdate(id, params, options = {}) {
        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error("Required parameter id was null or undefined when calling usersPartialUpdate.");
        }
        // verify required parameter 'data' is not null or undefined
        if (params === null || params === undefined) {
            throw new Error("Required parameter params was null or undefined when calling usersPartialUpdate.");
        }
        const data = {
            ...params,
            groups: params.groups?.join(","),
        };
        return apiPatch(this.fetch, urljoin(this.basePath, `/users/${encodeURIComponent(id)}`), data, options);
    }
}
