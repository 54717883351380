import urljoin from "url-join";
import { BaseAPI } from "./BaseApi";
import { apiGet, buildUrlWithPaginateQuery } from "./helpers";
import { toQueryString } from "utils/api";
import serializeOptions from "components/commons/TableBoard/utils/serializeOptions";
import { VIEW_FIELD } from "redux-store/reducers/contract";
class EndCustomerContractsApi extends BaseAPI {
    getEndCustomerContracts = async (query = {}) => {
        const { basePath, fetch: customFetch } = this;
        const contracts = await apiGet(buildUrlWithPaginateQuery(query, basePath, "customer-contract"), customFetch);
        return contracts;
    };
    getEndCustomerContractForEndCustomer = async (endCustomerId) => {
        const { basePath, fetch: customFetch } = this;
        const contracts = await apiGet(urljoin(basePath, "customer-contract/", "endCustomer/", endCustomerId), customFetch);
        return contracts;
    };
    getEndCustomerContractsForPromoter = async (promoterId) => {
        const { basePath, fetch: customFetch } = this;
        const contracts = await apiGet(urljoin(basePath, "customer-contract/", "promoter/", promoterId), customFetch);
        return contracts;
    };
    /**
     * @IMPORTANT : all fuzzy-search api endpoints are temporarly disabled
     */
    fuzzySearchForCustomerContractsTable = async (contractId, queryUnserialised, requestPath) => {
        const { basePath, fetch: customFetch } = this;
        const query = serializeOptions(queryUnserialised);
        const queryString = toQueryString(query);
        return apiGet(urljoin(basePath, "customer-contract/", "front-table/", "fuzzy-search/", `${requestPath}/`, contractId, queryString), customFetch);
    };
    fuzzySearchPromoterForCustomerContractsTable = async (contractId, queryUnserialised) => {
        return this.fuzzySearchForCustomerContractsTable(contractId, queryUnserialised, "by-promoter-name");
    };
    fuzzySearchEndcustomerNameForCustomerContractsTable = async (contractId, queryUnserialised) => {
        return this.fuzzySearchForCustomerContractsTable(contractId, queryUnserialised, "by-end-customer-name");
    };
    fuzzySearchEndcustomerEmailForCustomerContractsTable = async (contractId, queryUnserialised) => {
        return this.fuzzySearchForCustomerContractsTable(contractId, queryUnserialised, "by-end-customer-email");
    };
    fuzzySearchEndcustomerPhoneNumberForCustomerContractsTable = async (contractId, queryUnserialised) => {
        return this.fuzzySearchForCustomerContractsTable(contractId, queryUnserialised, "by-end-customer-phone-number");
    };
    fuzzySearchEndcustomerCityForCustomerContractsTable = async (contractId, queryUnserialised) => {
        return this.fuzzySearchForCustomerContractsTable(contractId, queryUnserialised, "by-end-customer-city");
    };
    fuzzySearchPurchasedProductSubTypeForCustomerContractsTable = async (contractId, queryUnserialised) => {
        return this.fuzzySearchForCustomerContractsTable(contractId, queryUnserialised, "by-purchased-product-sub-type");
    };
    /* @IMPORTANT : END of fuzzy-search temporarly disabled API endpoints */
    getEndCustomerContractsForTableExport = async (contractId, viewType, queryOptions = {}) => {
        const { basePath, fetch: customFetch } = this;
        const query = Object.keys(queryOptions).length
            ? toQueryString(queryOptions)
            : "";
        let apiUrl = urljoin(basePath, "customer-contract/", "front-table/", "export/", contractId, query);
        if (viewType === VIEW_FIELD) {
            apiUrl = urljoin(basePath, "customer-contract/", "front-table/", "export/", contractId, "field", query);
        }
        const resp = await customFetch(apiUrl, { method: "GET" });
        return resp;
    };
    getContractTableFieldsExport = async (contractId, queryOptions = {}) => {
        const { basePath, fetch: customFetch } = this;
        const query = Object.keys(queryOptions).length
            ? toQueryString(queryOptions)
            : "";
        const resp = await customFetch(urljoin(basePath, "customer-contract/", "front-table/", "fields-export/", contractId, query), { method: "GET" });
        return resp;
    };
    /**
     *
     * @deprecated
     */
    getEndCustomerContractsForTable = async (contractId, queryOptions = {}) => {
        const { basePath, fetch: customFetch } = this;
        const contracts = await apiGet(buildUrlWithPaginateQuery(queryOptions, basePath, "customer-contract/", "front-table/", contractId), customFetch);
        return contracts;
    };
    getContractDashboardViewEndCustomerForTable = async (contractId, queryOptions = {}) => {
        const { basePath, fetch: customFetch } = this;
        const contracts = await apiGet(buildUrlWithPaginateQuery(queryOptions, basePath, "customer-contract/", "front-table/", "end-customer/", contractId), customFetch);
        return contracts;
    };
    getContractDashboardViewFieldForTable = async (contractId, queryOptions = {}) => {
        const { basePath, fetch: customFetch } = this;
        const contracts = await apiGet(buildUrlWithPaginateQuery(queryOptions, basePath, "customer-contract/", "front-table/", "field/", contractId), customFetch);
        return contracts;
    };
    getTableEndCustomersCount = async (contractId, queryOptions = {}) => {
        const { basePath, fetch: customFetch } = this;
        const query = Object.keys(queryOptions).length
            ? toQueryString(queryOptions)
            : "";
        const contracts = await apiGet(urljoin(basePath, "customer-contract/", "front-table/", "end-customers-count", contractId, query), customFetch);
        return contracts;
    };
}
export default EndCustomerContractsApi;
